<template>
  <div style="background: #fff;height: 100%;
">
    <div class="hed">
      <div class="zonger">红包总额（元）</div>
      <div class="jiner">
        <span style="font-size: 17px;">￥</span>
        <span style="font-weight: bold;">{{list.totalAmount?list.totalAmount.toFixed(2):'0.00'}}</span>
      </div>
      <div class="tui">
        <div class="tui_a">直推红包：{{list.firstCount}}个</div>
        <div class="tui_b">转介红包：{{list.secondCount}}个</div>
      </div>
      <div class="hong_jies">
        <div class="left" style="width: 85px;">红包发放时间：</div>
        <div class="left fasong_sm">前6个即时发放，每天10、14、18、22时准点发放该时间前所有红包</div>
      </div>
    </div>
    <div class="list">
      <div class="list_a" v-for="(item,idx) in content" :key="idx">
        <!--  -->
        <img class="imgs" v-if="item.type == 0" src="../../assets/img/zhitui.png" alt />
        <img class="imgs" v-if="item.type == 1" src="../../assets/img/zhuanjie.png" alt />
        <img class="imgs" v-if="item.type == 2" src="../../assets/img/pengyouquan.png" alt />
        <img class="imgs" v-if="item.type == 3" src="../../assets/img/fanxian.png" alt />
        <div class="mian">
          <div class="mian_a">
            <span class="name_a" v-if="item.type == 0">直推红包</span>
            <span class="name_a" v-if="item.type == 1">转介红包</span>
            <span class="name_a" v-if="item.type == 2">分享朋友圈红包</span>
            <span class="name_a" v-if="item.type == 3">返现红包</span>
            <span class="name_wa" style="color:#3ac29f;" v-if="item.status == 1">(已发放)</span>
            <span class="name_wa" style="color: red;" v-else>(待发放)</span>
            <span class="name_bj" v-if="item.carNo">{{item.carNo}}</span>
            <span class="name_jin">+{{item.amount?item.amount.toFixed(2):'0.00'}}</span>
          </div>
          <div class="mian_b">
            <span class="shijian">{{item.createdDate.substr(5,11)}}</span>
            <span class="laizi">
              <span>来自：</span>
              <img class="touxiang" :src="item.fromImages" alt />
              <span style="margin-left: 8px;" class="name_c">{{item.fromName}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <van-empty v-if="content.length < 1" description="暂无数据~" />
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
export default {
  data() {
    return {
      gid: "",
      title: this.$route.query.title,
      aid: this.$route.query.aid,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      content: [],
      uid: "",
      list: {}
    };
  },
  methods: {},
  created() {
    // this.userInfo.userId 3418
    userModel
      .report({ uid: this.userInfo.userId, page: 0, size: 150, aid: this.aid })
      .then(res => {
        if (res.code == 200) {
          this.list = res.data;
          this.content = res.data.redPackLogDOPage.content;
        }
      });
  },
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style  lang="less" scoped>
.hed {
  // height: 165px;
  background: #ec1c24;
  color: #fff;
  text-align: center;
  // position: fixed;
  padding-bottom: 6px;
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
}
.fasong_sm {
  width: 74%;
}
.hong_jies {
  margin-top: 3px;
  text-align: left;
  line-height: 20px;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
  padding: 0 0 0 12px;
  transform: scale(0.93, 0.93);
  *font-size: 10px;
}
.zonger {
  font-size: 12px;
  transform: scale(0.93, 0.93);
  *font-size: 10px;

  font-size: 12px;
  padding-top: 10px;
}
.jiner {
  margin-top: 14px;
  font-size: 26px;
}
.tui {
  overflow: hidden;
  padding: 0 50px;
  margin-top: 16px;
}
.tui_a {
  float: left;
}
.tui_b {
  float: right;
}
.list {
  overflow: hidden;
  // padding-top: 165px;
}
.list_a {
  overflow: hidden;
  padding: 15px 15px 0px 10px;
}
.imgs {
  float: left;
  width: 50px;
  margin-right: 15px;
}
.mian {
  overflow: hidden;
  //   margin: 0 12px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 15px;
}
.mian_a {
  overflow: hidden;
}
.mian_b {
  color: #666;
  margin-top: 4px;
}
.name_a {
  font-size: 15px;
  color: #000;
  font-weight: bold;
  float: left;
}
.name_wa {
  font-size: 12px;
  font-weight: bold;
  float: left;
  margin-left: 5px;
}
.name_bj {
  font-size: 12px;
  float: left;
  margin-left: 5px;
  background: url(../../assets/img/chepaibj.png) no-repeat;
  padding: 0 3px;
  height: 24px;
  transform: scale(0.93);
  color: #fff;
  background-size: 100% 100%;
}
.name_jin {
  float: right;
  font-size: 17px;
  font-weight: bold;
  color: #f1bf3f;
}
.touxiang {
  width: 20px;
  height: 20px;
}
.laizi {
  padding-left: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.shijian {
  float: left;
  margin-top: 3px;
}
</style>